import {ref} from 'vue';

let windowWidth = ref(0);
let windowHeight = ref(0);
let isSSR = utilities.isSSR();

// dummy for server - server doesnt know what a window even is!
let windowSizeCompositionServer = () => {
    return {
        windowWidth, windowHeight
    };
}
let debounceTimeout;
let debounceDelay = 10;
let windowSizeCompositionClient = () => {
    let setWindowHeight = () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
    };
    
    // populate size right now
    setWindowHeight();
    
    // listen to window resize
    window.addEventListener('resize', () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = utilities.setClientTimeout(setWindowHeight, debounceDelay);
    });

    return {
        windowWidth, windowHeight
    };
}

export default isSSR ? windowSizeCompositionServer : windowSizeCompositionClient;
